<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layout/header";
import Footer from "@/components/layout/footer";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="less">
.page {
  width: 1200px;
}
</style>